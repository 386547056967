import {
  CalendarResponse,
  PageConfigResponse,
  PageSubscriptions,
  UserMetaDataResponse,
  UserType,
} from '@solin-fitness/types';
import React from 'react';
import styled from 'styled-components';
import YourAccessSection from './Components/YourAccessSection';
import CreatorUpcomingSessions from './Components/CreatorUpcomingSessions';
import CreatorLearnMore from './Components/CreatorLearnMore';
import CreatorTestimonialsSection from './Components/CreatorTestimonialsSection';
import Spacer, { Size } from 'shared/Spacer';
import { sort } from 'ramda';
import EmailListForm from './Components/EmailListForm';
import { usePublicCalendar } from 'queries/calendar';
import { usePrograms } from 'queries/programs';
import { Spinner } from 'shared/Spinner';
import { getSectionOrder } from './Components/creatorLandingPageHelpers';
import { DateTime } from 'luxon';
import { SubscriptionStatus } from '@solin-fitness/types';
import { useRouter } from 'next/router';
import { reactivateSubscription } from 'queries/user';
import { useMutation, useQueryClient } from 'react-query';
import { ReactivateSubscriptionModal } from './Components/ReactivateSubscriptionModal';
import { Media, MediaContextProvider } from 'media';
import { AccessIncludeSection } from 'new-components/creator-landing-page/your-access-section';
import { useSessions } from 'queries/sessions';
import { UpcomingSessionSection } from 'new-components/creator-landing-page/upcoming-session-section';
import { LearnMoreSection } from 'new-components/creator-landing-page/learn-more-section';
import { TestimonialSection } from 'new-components/creator-landing-page/testimonial-section';
import { Footer } from 'components/LandingPage/Footer';
import clsx from 'clsx';
import { ProgramsDisplayCarousel } from 'new-components/multiple-programs/display-carousel/programs-display-carousel';
import { LandingPageBannerSection } from 'new-components/creator-landing-page/banner-section';

const removePastCalendarItemsAndSort = (
  items: CalendarResponse[],
): CalendarResponse[] => {
  const currentDate = new Date();
  const upcomingCalendarItems = items.filter((item) => {
    const date = DateTime.fromJSDate(new Date(item.date))
      .plus({ minutes: item.data.length })
      .toJSDate();

    return date > currentDate;
  });

  const sortedCalendarItems = sort(
    (a: CalendarResponse, b: CalendarResponse) => {
      const aDate = new Date(a.date).getTime();
      const bDate = new Date(b.date).getTime();
      return aDate - bDate;
    },
    upcomingCalendarItems,
  );
  return sortedCalendarItems;
};

export const findLowestSubscription = (subscriptions: PageSubscriptions[]) => {
  const activeSubscriptions = subscriptions.filter((sub) => sub.isActive);
  if (activeSubscriptions.length === 0) {
    return null;
  }
  const sortedSubscriptions = sort(
    (a: PageSubscriptions, b: PageSubscriptions) => {
      const aPrice = a.amount;
      const bPrice = b.amount;
      return aPrice - bPrice;
    },
    activeSubscriptions,
  );

  return sortedSubscriptions[0];
};

interface Props {
  config: PageConfigResponse;
  metadata: UserMetaDataResponse | undefined;
}

const CreatorLandingPage = ({ config, metadata }: Props) => {
  const router = useRouter();

  const {
    url,
    id,
    config: {
      backgroundImage2PublicId,
      teaserThumbnailPublicId,
      teaserVideo,
      landingPageListItems,
      learnMoreImagePublicId,
      bio,
      subscriptions,
      testimonials,
    },
  } = config;

  const subscriptionsToPage = metadata?.subscriptions.filter(
    (sub) =>
      sub.pageId === id &&
      (sub.status === SubscriptionStatus.canceled ||
        sub.status === SubscriptionStatus.incompleteExpired),
  );

  const hasCanceledSubscription = !!subscriptionsToPage?.length;

  const lowestSubscription = findLowestSubscription(subscriptions);

  const { data: upcomingSessions } = usePublicCalendar(
    url,
    1,
    2,
    removePastCalendarItemsAndSort,
  );

  const { data: programsRaw } = usePrograms(url, undefined);

  const { data: sessions } = useSessions(url);

  const programs = programsRaw
    ?.filter((program) => program.published)
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

  const adminCreator = config.users.find(
    (user) => user.type === UserType.adminCreator,
  );

  const creatorName = adminCreator?.profile.firstName;

  const queryClient = useQueryClient();
  const reactivate = useMutation((subscriptionId: string) =>
    reactivateSubscription(subscriptionId),
  );
  const handleReactivate = () => {
    if (subscriptionsToPage?.length) {
      reactivate.mutate(subscriptionsToPage[0].subscriptionId, {
        onSuccess: () => {
          queryClient.invalidateQueries('metadata');
          router.push(url);
        },
      });
    }
  };
  const isOpenReactivateModal = !!router.query.reactivate;
  const closeReactivateModal = () => {
    router.push(url, undefined, { shallow: true });
  };

  return (
    <>
      {programs && upcomingSessions && sessions ? (
        (() => {
          const hasSubscription = !!lowestSubscription;
          const hasUpcomingSessions = !!upcomingSessions.length;
          const hasPrograms = !!programs.length;
          const hasTestimonials = !!testimonials?.length;

          const sectionOrder = getSectionOrder(
            hasSubscription,
            hasUpcomingSessions,
            hasPrograms,
            hasTestimonials,
          );

          return (
            <MediaContextProvider>
              <LandingPageBannerSection
                verticalImage={config.config.verticalBackgroundImage}
                horizontalImage={config.config.backgroundImage1PublicId}
                pageName={config.config.pageName}
                tagline={config.config.pageDescription}
                page={url}
                pageHasPrograms={hasPrograms}
                pageHasSubscriptions={hasSubscription}
                pageHasTrial={!!config.config.trialPeriodDays}
              />

              <Media greaterThanOrEqual="md">
                <section
                  className={clsx(
                    'even:bg-off-white-500',
                    hasSubscription ? 'mb-40' : 'mb-0',
                  )}
                >
                  <YourAccessSection
                    page={url}
                    image={teaserThumbnailPublicId}
                    video={teaserVideo}
                    details={landingPageListItems}
                    hasSubscription={!!lowestSubscription}
                    trialPeriod={config.config.trialPeriodDays}
                    hasCanceledSubscription={!!hasCanceledSubscription}
                  />
                </section>

                <section
                  className={clsx(
                    'even:bg-off-white-500',
                    hasUpcomingSessions ? 'mb-40' : 'mb-0',
                  )}
                >
                  <CreatorUpcomingSessions
                    page={url}
                    image={backgroundImage2PublicId}
                    imageRight={sectionOrder[1]}
                    upcomingSessions={upcomingSessions || []}
                    hasSubscription={!!lowestSubscription}
                    purchasedLiveWorkouts={metadata?.purchasedClasses || []}
                    purchasedEvents={metadata?.purchasedEvents || []}
                    trialPeriod={config.config.trialPeriodDays}
                    hasCanceledSubscription={!!hasCanceledSubscription}
                  />
                </section>

                {programs && programs.length > 0 && (
                  <div
                    className={clsx(
                      'mb-40 flex items-center justify-center',
                      'even:bg-off-white-500',
                    )}
                  >
                    <ProgramsDisplayCarousel
                      page={url}
                      programs={programs}
                      purchasedPrograms={metadata?.purchasedPrograms || []}
                      pageHasSubscription={hasSubscription}
                      isSubscribed={false}
                    />
                  </div>
                )}

                <section className="even:bg-off-white-500">
                  <CreatorLearnMore
                    page={url}
                    image={learnMoreImagePublicId}
                    imageRight={sectionOrder[3]}
                    bio={bio}
                    hasSubscription={!!lowestSubscription}
                    trialPeriod={config.config.trialPeriodDays}
                    hasCanceledSubscription={!!hasCanceledSubscription}
                  />
                </section>

                <section
                  className={clsx(
                    'even:bg-off-white-500',
                    hasTestimonials ? 'pt-40' : 'pt-0',
                    !hasSubscription ? 'mb-40' : 'mb-0',
                  )}
                >
                  <CreatorTestimonialsSection
                    page={url}
                    noBackground={sectionOrder[4]}
                    testimonials={testimonials}
                    hasSubscription={!!lowestSubscription}
                    trialPeriod={config.config.trialPeriodDays}
                    hasCanceledSubscription={!!hasCanceledSubscription}
                  />
                </section>

                {!!!hasSubscription ? (
                  <section className="even:bg-off-white-500">
                    <div className="ml-auto mr-auto max-w-[1700px] pt-12 pb-24">
                      <EmailListWrap>
                        <EmailListForm
                          size="large"
                          creator={creatorName}
                          page={url}
                        />
                      </EmailListWrap>
                    </div>
                  </section>
                ) : null}
              </Media>

              {/* mobile */}
              <Media lessThan="md">
                {hasSubscription ? (
                  <AccessIncludeSection
                    details={landingPageListItems}
                    video={teaserVideo}
                  />
                ) : null}

                {programs.length ? (
                  <section
                    className={clsx(
                      'flex items-center justify-center pt-11 pb-6',
                      'even:bg-off-white-500',
                    )}
                  >
                    <ProgramsDisplayCarousel
                      page={url}
                      programs={programs}
                      purchasedPrograms={metadata?.purchasedPrograms || []}
                      pageHasSubscription={hasSubscription}
                      isSubscribed={false}
                    />
                  </section>
                ) : null}

                <UpcomingSessionSection
                  sessions={sessions}
                  page={url}
                  className="even:bg-off-white-500"
                />
                <TestimonialSection
                  testimonials={testimonials}
                  className="even:bg-off-white-500"
                />
                <LearnMoreSection
                  bio={bio}
                  page={url}
                  hasSubscription={!!lowestSubscription}
                />
              </Media>

              <FooterWrap>
                <Media greaterThanOrEqual="md">
                  <Spacer
                    size={Size.spacing96}
                    axis="vertical"
                    color={
                      sectionOrder[6] ? undefined : 'var(--color-off-white-500)'
                    }
                  />
                </Media>
                <Footer theme="light" />
              </FooterWrap>

              {hasCanceledSubscription ? (
                <ReactivateSubscriptionModal
                  isOpen={isOpenReactivateModal}
                  onDismiss={closeReactivateModal}
                  onReactivate={handleReactivate}
                  isLoading={reactivate.isLoading}
                />
              ) : null}
            </MediaContextProvider>
          );
        })()
      ) : (
        <div className="flex items-center justify-center pt-14 sm:pt-20">
          <Spinner
            size="large"
            color="var(--color-primary-500)"
            ringColor="var(--color-gray-500)"
          />
        </div>
      )}
    </>
  );
};

const FooterWrap = styled.div`
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
`;

const EmailListWrap = styled.div`
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
`;

export default CreatorLandingPage;
