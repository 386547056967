import React from 'react';
import styled from 'styled-components';
import {
  SectionTitle,
  DesktopMediaContainer,
  AspectRatioBox,
  ImageAbsoluteContainer,
  MobileMediaContainer,
  SessionContainerBase,
  RightMainContainer,
  LeftMainContainer,
} from './CreatorLandingPage.styles';
import Button from 'shared/Button';
import { buildImageUrl } from 'cloudinary-build-url';
import Image from 'next/image';
import { SIGN_UP_SUBSCRIPTION } from 'constants/routes';
import { TypeVariant } from 'shared/Typography';
import ShowMoreText from 'components/ShowMoreText';
import { getCTAText } from './creatorLandingPageHelpers';

interface Props {
  page: string;
  image: string;
  imageRight?: boolean;
  bio: string;
  hasSubscription: boolean;
  trialPeriod: number;
  hasCanceledSubscription: boolean;
}

const CreatorLearnMore = ({
  page,
  image,
  imageRight,
  bio,
  hasSubscription,
  trialPeriod,
  hasCanceledSubscription,
}: Props) => {
  if (!bio) {
    return null;
  }

  const blurredUrl = buildImageUrl(image, {
    transformations: {
      effect: {
        name: 'blur',
        value: 1000,
      },
      quality: 1,
    },
  });

  const desktopImageSrc = buildImageUrl(image, {
    transformations: {
      quality: 100,
    },
  });

  const mobileImageSrc = buildImageUrl(image, {
    transformations: {
      quality: 100,
    },
  });

  const MainComponent = imageRight ? LeftMainContainer : RightMainContainer;

  const href = hasCanceledSubscription
    ? `${page}/?reactivate=true`
    : SIGN_UP_SUBSCRIPTION(page);

  return (
    <SectionContainer>
      {imageRight ? null : (
        <DesktopMediaContainer>
          <AspectRatioBox>
            <ImageAbsoluteContainer>
              <Image
                src={desktopImageSrc}
                placeholder="blur"
                blurDataURL={blurredUrl}
                layout="fill"
                alt="Creator image"
                objectFit="cover"
              />
            </ImageAbsoluteContainer>
          </AspectRatioBox>
        </DesktopMediaContainer>
      )}
      <MainComponent>
        <SectionTitle>Learn More</SectionTitle>
        <MobileMediaContainer>
          <ImageAbsoluteContainer>
            <Image
              src={mobileImageSrc}
              placeholder="blur"
              blurDataURL={blurredUrl}
              layout="fill"
              alt="Creator image"
              objectFit="cover"
            />
          </ImageAbsoluteContainer>
        </MobileMediaContainer>
        <BioContainer>
          <ShowMoreText
            typeVariant={{
              default: TypeVariant.bodyLarge01,
              phone: TypeVariant.body01,
            }}
            color="var(--color-black-500)"
            text={bio}
            title="Learn More"
            characterLimit={300}
          />
        </BioContainer>
        {hasSubscription ? (
          <Button
            variant="fill"
            size="medium"
            link
            href={href}
            shallow={hasCanceledSubscription}
          >
            {getCTAText(!!trialPeriod, hasCanceledSubscription)}
          </Button>
        ) : null}
      </MainComponent>
      {imageRight ? (
        <DesktopMediaContainer>
          <AspectRatioBox>
            <ImageAbsoluteContainer>
              <Image
                src={desktopImageSrc}
                placeholder="blur"
                blurDataURL={blurredUrl}
                layout="fill"
                alt="Creator image"
                objectFit="cover"
              />
            </ImageAbsoluteContainer>
          </AspectRatioBox>
        </DesktopMediaContainer>
      ) : null}
    </SectionContainer>
  );
};

const SectionContainer = styled(SessionContainerBase)`
  background: var(--background-color);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-top: var(--spacing-48);
    padding-bottom: var(--spacing-48);
  }
`;

const BioContainer = styled.div`
  @media ${(p) => p.theme.queries.phoneAndDown} {
    max-width: 500px;
    padding: 0 30px;
  }
`;

export default CreatorLearnMore;
