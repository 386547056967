import { api, Methods, serverApi } from 'services/api';
import { Sessions } from '@solin-fitness/types';
import { useQuery } from 'react-query';
import { IncomingMessage } from 'http';

export const sessionQueryKeys = {
  all: (page: string) => ['sessions', page] as const,
};

export const serverGetSessions = async (req: IncomingMessage, page: string) =>
  await serverApi(req, Methods.get, '/sessions', {}, { xPage: page });

export const getSessions = async (xPage: string) =>
  await api<Sessions[]>(Methods.get, '/sessions', {}, { xPage });

export const useSessions = (page: string) =>
  useQuery(sessionQueryKeys.all(page), () => getSessions(page));
