import {
  ProgramResponse,
  PurchasedProgramResponse,
} from '@solin-fitness/types';
import clsx from 'clsx';
import { useRef, useState, useEffect } from 'react';
import Icon, { IconNames } from 'shared/Icons';
import { ProgramCarouselCard } from './program-carousel-card';

const ProgramsDisplayCarousel = ({
  page,
  programs,
  purchasedPrograms,
  isSubscribed,
  pageHasSubscription,
  className,
}: {
  page: string;
  programs: ProgramResponse[];
  purchasedPrograms: PurchasedProgramResponse[];
  isSubscribed: boolean;
  pageHasSubscription: boolean;
  className?: string;
}) => {
  const outerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  useEffect(() => {
    if (ref.current && outerRef.current) {
      const scrollWidth = ref.current.scrollWidth;
      const clientWidth = outerRef.current.clientWidth;
      if (scrollWidth > clientWidth) {
        setShowRight(true);
      }
    }
  }, [ref, outerRef]);

  const scrollRight = () => {
    if (ref.current) {
      ref.current.scrollTo({
        left: ref.current.scrollLeft + 317,
        behavior: 'smooth',
      });
    }
  };

  const scrollLeft = () => {
    if (ref.current) {
      ref.current.scrollTo({
        left: ref.current.scrollLeft - 317,
        behavior: 'smooth',
      });
    }
  };

  if (programs.length) {
    return (
      <section className={clsx('w-full pb-6 sm:pb-0', className)}>
        {/* negative margin and padding adding here to add space when hash tag link is clicked */}
        <span id="programs" className="-mt-14 pb-14 sm:-mt-16 sm:pb-16 block" />
        <h2 className="text-2xl font-hk font-medium leading-sm sm:text-4xl sm:tracking-lg px-5 pb-6 sm:px-16 sm:pb-12 sm:text-center">
          Programs &#x26; Challenges
        </h2>
        <div
          ref={outerRef}
          className="flex justify-start sm:justify-center relative"
        >
          <div
            ref={ref}
            style={{ scrollbarWidth: 'none' }}
            className="max-w-full overflow-x-auto overflow-y-hidden px-5 scroll-px-5 sm:px-16 sm:scroll-px-16 snap-mandatory snap-x whitespace-nowrap"
            onScroll={(e) => {
              const target = e.currentTarget;
              if (target.scrollLeft === 0) {
                setShowLeft(false);
              } else {
                setShowLeft(true);
              }

              if (
                target.scrollLeft + target.clientWidth ===
                target.scrollWidth
              ) {
                setShowRight(false);
              } else {
                setShowRight(true);
              }
            }}
          >
            {programs.map((program) => {
              const isPurchased = purchasedPrograms.some(
                (pp) => pp.program.id === program.id,
              );

              const hasAccess =
                (program.isIncludedInMembership && isSubscribed) || isPurchased;

              return (
                <article
                  key={program.id}
                  className="inline-block snap-center sm:snap-start pr-4 last-of-type:pr-0"
                >
                  <ProgramCarouselCard
                    count={programs.length}
                    page={page}
                    program={program}
                    hasAccess={hasAccess}
                    pageHasSubscription={pageHasSubscription}
                  />
                </article>
              );
            })}
            <div className="hidden sm:flex justify-center items-center absolute top-0 left-0 bottom-0 w-[74px]">
              {showLeft ? (
                <div
                  className="w-10 h-10 flex justify-center items-center rounded-full shrink-0 bg-white-500 opacity-90 cursor-pointer shadow-icon"
                  onClick={scrollLeft}
                >
                  <Icon
                    id={IconNames.chevronLeft}
                    color="var(--color-black-500)"
                    width={27}
                    height={27}
                  />
                </div>
              ) : null}
            </div>
            <div className="hidden sm:flex justify-center items-center absolute top-0 right-0 bottom-0 w-[74px] ">
              {showRight ? (
                <div
                  className="w-10 h-10 flex justify-center items-center rounded-full shrink-0 bg-white-500 opacity-90 cursor-pointer shadow-icon"
                  onClick={scrollRight}
                >
                  <Icon
                    id={IconNames.chevronRight}
                    color="var(--color-black-500)"
                    width={27}
                    height={27}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    );
  }

  return null;
};

export { ProgramsDisplayCarousel };
