import { AddPhoneNumber } from '@solin-fitness/types';
import { useMutation } from 'react-query';
import { api, Methods } from 'services/api';

const addToPhoneNumberList = async (data: AddPhoneNumber) => {
  await api(Methods.post, '/user/phone-number', data);
};

const useAddPhoneNumber = () => {
  return useMutation((data: AddPhoneNumber) => addToPhoneNumberList(data));
};

export { useAddPhoneNumber };
