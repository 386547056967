import styled from 'styled-components';
import Modal from 'shared/Modal';
import Button from 'shared/Button';

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
  isLoading: boolean;
  onReactivate: () => void;
};

const ReactivateSubscriptionModal = ({
  isOpen,
  onDismiss,
  isLoading,
  onReactivate,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      title="We are excited to have you back!"
      subtitle="Please confirm your reactivation below."
      maxWidth={500}
    >
      <ButtonRow>
        <Button size="small" variant="black-outline" onClick={onDismiss}>
          Cancel
        </Button>
        <Button
          size="small"
          variant="fill"
          onClick={onReactivate}
          isLoading={isLoading}
        >
          Reactivate
        </Button>
      </ButtonRow>
    </Modal>
  );
};

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: var(--spacing-24);
`;

export { ReactivateSubscriptionModal };
