import React, { useState } from 'react';
import styled from 'styled-components';
import {
  SectionTitle,
  HeaderText,
  DetailText,
  RichDetailText,
  DesktopMediaContainer,
  AspectRatioBox,
  ImageAbsoluteContainer,
  ButtonContainer,
  MobileMediaContainer,
  SessionContainerBase,
  RightMainContainer,
  InfoContainer,
  HeaderRow,
  DetailsContainer,
  IconWrapper,
  DetailsRow,
  DetailsWithIconWrap,
  ButtonRow,
  ArrowButton,
  MobileArrowButton,
  LeftMainContainer,
} from './CreatorLandingPage.styles';
import Button from 'shared/Button';
import { buildImageUrl } from 'cloudinary-build-url';
import Image from 'next/image';
import Icon, { IconNames } from 'shared/Icons';
import {
  CalendarResponse,
  EventResponse,
  LiveWorkoutResponse,
  PurchasedClassesResponse,
  PurchasedEventResponse,
} from '@solin-fitness/types';
import { DateTime } from 'luxon';
import { UrlObject } from 'url';
import {
  SIGN_UP_EVENT,
  SIGN_UP_LIVE_WORKOUT,
  SIGN_UP_SUBSCRIPTION,
} from 'constants/routes';
import { hasPurchasedEvent, hasPurchasedLiveWorkout } from 'services/metadata';
import { useRouter } from 'next/router';
import { RESIZE_TYPES } from '@cld-apis/utils';
import { getCTAText } from './creatorLandingPageHelpers';

declare type Url = string | UrlObject;

enum Location {
  liveWorkout = 'Live Workout',
  event = 'Event',
  workoutDrop = 'Drop',
}
interface SessionDetails {
  buttonText?: string;
  href?: Url;
  showButton: boolean;
  dataTest?: string;
}

/**
 * Getter function for session details for components that build an upcoming session
 * @param item - currently selected session
 * @param page - creator's page
 * @param defaultLang - default language pushing to subscription
 * @returns SessionDetails
 */
const getSessionDetails = (
  item: CalendarResponse,
  page: string,
  hasSubscription: boolean,
  trialPeriod: number,
  purchasedLiveWorkouts: PurchasedClassesResponse[],
  purchasedEvents: PurchasedEventResponse[],
  hasCanceledSubscription: boolean,
): SessionDetails => {
  const { type, data } = item;
  const defaultDetails = hasSubscription
    ? {
        buttonText: getCTAText(!!trialPeriod, hasCanceledSubscription),
        href: hasCanceledSubscription
          ? `${page}/?reactivate=true`
          : SIGN_UP_SUBSCRIPTION(page),
        showButton: true,
        dataTest: 'purchaseLiveButton',
      }
    : {
        showButton: false,
      };

  switch (type) {
    case 'workoutDrop':
      return defaultDetails;
    case 'liveWorkout': {
      const liveWorkout = data as LiveWorkoutResponse;
      // Live workout not for sale (subscribe to access)
      if (!liveWorkout.isForSale) {
        return defaultDetails;
      }

      const liveWorkoutAmount = `Purchase $${(liveWorkout.amount / 100).toFixed(
        2,
      )}`;
      const isLiveWorkoutPurchased = hasPurchasedLiveWorkout(
        purchasedLiveWorkouts,
        liveWorkout.id,
      );

      if (isLiveWorkoutPurchased) {
        return {
          buttonText: 'Purchased',
          showButton: true,
        };
      }

      if (liveWorkout.isIncludedInMembership) {
        return {
          buttonText: liveWorkoutAmount,
          href: SIGN_UP_LIVE_WORKOUT(page, liveWorkout.id),
          showButton: true,
          dataTest: 'purchaseLiveButton',
        };
      } else {
        return {
          buttonText: liveWorkoutAmount,
          href: SIGN_UP_LIVE_WORKOUT(page, liveWorkout.id),
          showButton: true,
          dataTest: 'purchaseLiveButton',
        };
      }
    }
    case 'event': {
      const event = data as EventResponse;
      const eventAmount = `Purchase $${(event.amount / 100).toFixed(2)}`;

      const isEventPurchased = hasPurchasedEvent(purchasedEvents, event.id);
      if (isEventPurchased) {
        return {
          buttonText: 'Purchased',
          showButton: true,
        };
      }
      return {
        buttonText: eventAmount,
        href: SIGN_UP_EVENT(page, event.id),
        showButton: true,
      };
    }
  }
};

interface Props {
  page: string;
  image: string;
  imageRight?: boolean;
  upcomingSessions: CalendarResponse[];
  hasSubscription: boolean;
  purchasedLiveWorkouts: PurchasedClassesResponse[];
  purchasedEvents: PurchasedEventResponse[];
  trialPeriod: number;
  hasCanceledSubscription: boolean;
}

const CreatorUpcomingSessions = ({
  page,
  image,
  imageRight,
  upcomingSessions,
  hasSubscription,
  purchasedLiveWorkouts,
  purchasedEvents,
  trialPeriod,
  hasCanceledSubscription,
}: Props) => {
  const router = useRouter();

  const [index, setIndex] = useState<number>(0);

  if (!!!upcomingSessions.length) {
    return null;
  }

  const handlePrevious = () => setIndex(index - 1);
  const handleNext = () => setIndex(index + 1);

  const blurredUrl = buildImageUrl(image, {
    transformations: {
      effect: {
        name: 'blur',
        value: 1000,
      },
      quality: 1,
    },
  });

  const desktopImageSrc = buildImageUrl(image, {
    transformations: {
      resize: {
        type: RESIZE_TYPES.SCALE,
        width: 1000,
        aspectRatio: '16:9',
      },
      quality: 'auto',
    },
  });

  const mobileImageSrc = buildImageUrl(image, {
    transformations: {
      resize: {
        type: RESIZE_TYPES.SCALE,
        width: 750,
        aspectRatio: '16:9',
      },
      quality: 'auto',
    },
  });

  const onlyPurchaseLiveSessionForCypressTest = upcomingSessions.filter(
    (item) =>
      item.type === 'liveWorkout' &&
      (item.data as LiveWorkoutResponse).isForSale,
  );
  const isPurchaseTest = router.asPath.includes('?purchaseLiveTest=1');

  const session = isPurchaseTest
    ? onlyPurchaseLiveSessionForCypressTest[0]
    : upcomingSessions[index];

  const { data, date, type } = session;

  const startDate = DateTime.fromJSDate(new Date(date)).toFormat('DDDD');

  const time = `${DateTime.fromJSDate(new Date(date)).toFormat(
    't',
  )} - ${DateTime.fromJSDate(new Date(date))
    .plus({ minutes: data.length })
    .toFormat('t')}`;

  const location = Location[type];

  const { buttonText, href, showButton, dataTest } = getSessionDetails(
    session,
    page,
    hasSubscription,
    trialPeriod,
    purchasedLiveWorkouts,
    purchasedEvents,
    hasCanceledSubscription,
  );

  const MainComponent = imageRight ? Left : Right;

  return (
    <SectionContainer>
      {imageRight ? null : (
        <DesktopMediaContainer>
          <AspectRatioBox>
            <ImageAbsoluteContainer>
              <Image
                src={desktopImageSrc}
                placeholder="blur"
                blurDataURL={blurredUrl}
                layout="fill"
                alt="Creator image"
                objectFit="cover"
              />
            </ImageAbsoluteContainer>
          </AspectRatioBox>
        </DesktopMediaContainer>
      )}

      <MainComponent>
        <SectionTitle>Upcoming Sessions</SectionTitle>
        <MobileMediaContainer>
          <ImageAbsoluteContainer>
            <Image
              src={mobileImageSrc}
              placeholder="blur"
              blurDataURL={blurredUrl}
              layout="fill"
              alt="Creator image"
              objectFit="cover"
            />
          </ImageAbsoluteContainer>
        </MobileMediaContainer>
        <InfoContainer>
          <HeaderRow>
            <MobileArrowButton onClick={handlePrevious} disabled={index === 0}>
              <Icon id={IconNames.chevronLeft} color="var(--chevron-color)" />
            </MobileArrowButton>
            <HeaderText>{session.data.title}</HeaderText>
            <MobileArrowButton
              onClick={handleNext}
              disabled={index === upcomingSessions.length - 1}
            >
              <Icon id={IconNames.chevronRight} color="var(--chevron-color)" />
            </MobileArrowButton>
          </HeaderRow>
          <DetailsContainer>
            <MobileDescriptionWrap>
              <RichDetailText
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            </MobileDescriptionWrap>
            <DetailsWithIconWrap>
              <DetailsRow>
                <IconWrapper>
                  <Icon
                    id={IconNames.calendar}
                    color="var(--color-black-700)"
                  />
                </IconWrapper>
                <DetailText>{startDate}</DetailText>
              </DetailsRow>
              <DetailsRow>
                <IconWrapper>
                  <Icon id={IconNames.clock} color="var(--color-black-700)" />
                </IconWrapper>
                <DetailText>{time}</DetailText>
              </DetailsRow>
              <DetailsRow>
                <IconWrapper>
                  <Icon
                    id={IconNames.location}
                    color="var(--color-black-700)"
                  />
                </IconWrapper>
                <DetailText>{location}</DetailText>
              </DetailsRow>
            </DetailsWithIconWrap>
          </DetailsContainer>
        </InfoContainer>
        <ButtonContainer>
          <ButtonRow>
            {showButton ? (
              !!href ? (
                <Button
                  variant="fill"
                  size="medium"
                  link
                  href={href}
                  shallow={hasCanceledSubscription}
                  dataTest={dataTest}
                >
                  {buttonText}
                </Button>
              ) : (
                <Button
                  variant="fill"
                  size="medium"
                  disabled
                  withIcon
                  iconId={IconNames.check}
                  dataTest={dataTest}
                >
                  {buttonText}
                </Button>
              )
            ) : null}
            {session.type === 'event' && (
              <Button
                variant="ghost"
                size="medium"
                withIcon
                iconId={IconNames.chevronRight}
                link
                href={{
                  pathname: '/[creatorPage]/event/[eventId]',
                  query: {
                    creatorPage: page,
                    eventId: session.data.id,
                  },
                }}
              >
                Learn More
              </Button>
            )}
            <ArrowContainer>
              <ArrowButton onClick={handlePrevious} disabled={index === 0}>
                <Icon id={IconNames.chevronLeft} color="var(--chevron-color)" />
              </ArrowButton>
              <ArrowButton
                onClick={handleNext}
                disabled={index === upcomingSessions.length - 1}
              >
                <Icon
                  id={IconNames.chevronRight}
                  color="var(--chevron-color)"
                />
              </ArrowButton>
            </ArrowContainer>
          </ButtonRow>
        </ButtonContainer>
      </MainComponent>

      {imageRight ? (
        <DesktopMediaContainer>
          <AspectRatioBox>
            <ImageAbsoluteContainer>
              <Image
                src={desktopImageSrc}
                placeholder="blur"
                blurDataURL={blurredUrl}
                layout="fill"
                alt="Creator image"
                objectFit="cover"
              />
            </ImageAbsoluteContainer>
          </AspectRatioBox>
        </DesktopMediaContainer>
      ) : null}
    </SectionContainer>
  );
};

const SectionContainer = styled(SessionContainerBase)`
  background: var(--background-color);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-top: var(--spacing-48);
    padding-bottom: var(--spacing-48);
  }
`;

const Right = styled(RightMainContainer)`
  @media ${(p) => p.theme.queries.phoneAndDown} {
    gap: (var--spacing-24);
    padding: 0;
  }
`;

const Left = styled(LeftMainContainer)`
  @media ${(p) => p.theme.queries.phoneAndDown} {
    gap: (var--spacing-24);
    padding: 0;
  }
`;

const MobileDescriptionWrap = styled.div`
  display: none;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ArrowContainer = styled.div`
  margin-left: auto;
  display: flex;
  gap: 10px;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: none;
  }
`;

export default CreatorUpcomingSessions;
