import clsx from 'clsx';
import Button from 'shared/Button';

const LearnMoreSection = ({
  bio,
  page,
  hasSubscription,
  className,
}: {
  bio: string | undefined;
  page: string;
  hasSubscription: boolean;
  className?: string;
}) => {
  if (bio) {
    return (
      <section className={clsx('py-11 px-5', className)}>
        <h2 className="font-hk text-2xl font-medium leading-sm pb-6 ">
          Learn More
        </h2>
        <p className="font-hk text-base leading-md text-black-500">{bio}</p>
        {hasSubscription ? (
          <div className="pt-6">
            <Button
              variant="fill"
              size="medium"
              link
              href={`/${page}/subscription/purchase`}
            >
              Start Today
            </Button>
          </div>
        ) : null}
      </section>
    );
  }

  return null;
};

export { LearnMoreSection };
