import { MediaContextProvider, Media } from 'media';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { buildImageUrl } from 'cloudinary-build-url';
import Image from 'next/image';
import Button from 'shared/Button';
import { IconNames } from 'shared/Icons';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { GetNotifiedModal } from './get-notified-modal';
import { useState } from 'react';

const DesktopBannerImage = dynamic(() => import('./desktop-banner-image'), {
  ssr: false,
});

type LandingPageBannerProps = {
  verticalImage: string;
  horizontalImage: string;
  pageName: string;
  tagline: string;
  page: string;
  pageHasPrograms: boolean;
  pageHasSubscriptions: boolean;
  pageHasTrial: boolean;
};

const LandingPageBannerSection = ({
  verticalImage,
  horizontalImage,
  ...rest
}: LandingPageBannerProps) => {
  const blurredMobileUrl = buildImageUrl(verticalImage, {
    transformations: {
      effect: {
        name: 'blur',
        value: 1000,
      },
      quality: 1,
    },
  });

  const mobileImageSrc = buildImageUrl(verticalImage, {
    transformations: {
      quality: 100,
    },
  });

  return (
    <section className="pb-0 sm:pb-20">
      <MediaContextProvider>
        <Media greaterThanOrEqual="md">
          <DesktopBannerImage image={horizontalImage}>
            <BannerInfo {...rest} />
          </DesktopBannerImage>
        </Media>

        <Media lessThan="md">
          <AspectRatio.Root ratio={9 / 16} className="bg-black-500">
            <Image
              src={mobileImageSrc}
              blurDataURL={blurredMobileUrl}
              placeholder="blur"
              layout="fill"
              objectFit="cover"
              priority
              quality={100}
            />
            <ImageGradient />

            <BannerInfo {...rest} />
          </AspectRatio.Root>
        </Media>
      </MediaContextProvider>
    </section>
  );
};

export { LandingPageBannerSection };

const ImageGradient = () => {
  return (
    <div className="absolute h-2/5 left-0 right-0 bottom-0 bg-gradient-to-t from-black-700 to-transparent" />
  );
};

const BannerInfo = ({
  pageName,
  tagline,
  page,
  pageHasSubscriptions,
  pageHasPrograms,
  pageHasTrial,
}: Omit<LandingPageBannerProps, 'verticalImage' | 'horizontalImage'>) => {
  const [isOpen, setIsOpen] = useState(false);

  const callToActionText = pageHasTrial ? 'Start Free Trial' : 'Start Today';

  const headerText =
    !pageHasPrograms && !pageHasSubscriptions
      ? `${pageName} coming soon`
      : pageName;

  const showTagline =
    !pageHasPrograms && !pageHasSubscriptions
      ? false
      : pageHasPrograms && !pageHasSubscriptions
      ? false
      : true;

  return (
    <article className="relative px-5 py-8 flex flex-col justify-end h-full sm:px-16 sm:py-12">
      <motion.h2
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="type-subtitle-2 sm:type-header-2 text-white-500  pb-2 "
      >
        {headerText}
      </motion.h2>
      {showTagline ? (
        <motion.h3
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="type-body-large-bold sm:type-subtitle-2 text-white-500 pb-4"
        >
          {tagline}
        </motion.h3>
      ) : null}
      <motion.div
        initial={{ x: '-100%' }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', duration: 0.75, delay: 1 }}
        className="flex gap-2"
      >
        {pageHasSubscriptions ? (
          <Button
            variant="fill"
            size={{ default: 'large', phone: 'medium' }}
            link
            href={`/${page}/subscription/purchase`}
          >
            {callToActionText}
          </Button>
        ) : null}
        {pageHasPrograms && !pageHasSubscriptions ? (
          <Button
            variant="transparent"
            size={{ default: 'large', phone: 'medium' }}
            onClick={() => {
              const products = document.getElementById('programs');
              products?.scrollIntoView({ behavior: 'smooth' });
            }}
            withIcon
            iconId={IconNames.arrowDown}
          >
            View Products
          </Button>
        ) : null}
        {!pageHasPrograms && !pageHasSubscriptions ? (
          <Button
            variant="fill"
            size={{ default: 'large', phone: 'medium' }}
            onClick={() => setIsOpen(true)}
          >
            Get Notified
          </Button>
        ) : null}
      </motion.div>

      <GetNotifiedModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </article>
  );
};
