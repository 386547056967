import Button from 'shared/Button';
import ReactPlayer from 'react-player';
import { useState } from 'react';

const AccessIncludeSection = ({
  details,
  video,
}: {
  details: string[];
  video?: string;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <section className="flex flex-col justify-start gap-6 px-5 py-11 even:bg-off-white-500">
      <h2 className="type-subtitle-1 text-black-700">Access Includes</h2>
      <ul>
        {details.map((detail, index) => (
          <Detail key={index} detail={detail} />
        ))}
      </ul>

      {video ? (
        <Button
          variant="black-outline"
          size="medium"
          onClick={() => setIsPlaying(true)}
        >
          Watch My Video
        </Button>
      ) : null}
      {isPlaying ? (
        <ReactPlayer
          key="mobile-video"
          url={video}
          playing
          controls
          width="100%"
          height="100%"
          onPause={() => setIsPlaying(false)}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
              },
            },
          }}
        />
      ) : null}
    </section>
  );
};

export { AccessIncludeSection };

const Detail = ({ detail }: { detail: string }) => {
  return (
    <li className="font-hk text-md text-black-700 pb-5 last:pb-0">{detail}</li>
  );
};
