import React from 'react';
import styled from 'styled-components';
import Button from 'shared/Button';
import {
  SectionTitle,
  SessionContainerBase,
} from './CreatorLandingPage.styles';
import Testimonial from 'components/Testimonial';
import { PageTestimonials } from '@solin-fitness/types';
import { SIGN_UP_SUBSCRIPTION } from 'constants/routes';
import { getCTAText } from './creatorLandingPageHelpers';

interface Props {
  page: string;
  testimonials: PageTestimonials[];
  hasSubscription: boolean;
  noBackground?: boolean;
  trialPeriod: number;
  hasCanceledSubscription: boolean;
}

const CreatorTestimonialsSection = ({
  page,
  testimonials,
  hasSubscription,
  noBackground,
  trialPeriod,
  hasCanceledSubscription,
}: Props) => {
  if (!testimonials.length) {
    return null;
  }

  const href = hasCanceledSubscription
    ? `${page}/?reactivate=true`
    : SIGN_UP_SUBSCRIPTION(page);

  return (
    <SectionContainer>
      <Header>
        <SectionTitle>What People Are Saying</SectionTitle>
      </Header>

      <TestimonialContainer>
        {testimonials.map(({ name, image, testimonial }, index) => (
          <TestimonialItem key={index}>
            <Testimonial name={name} image={image} testimonial={testimonial} />
          </TestimonialItem>
        ))}
      </TestimonialContainer>
      {hasSubscription ? (
        <Button
          variant="fill"
          size="medium"
          link
          href={href}
          shallow={hasCanceledSubscription}
        >
          {getCTAText(!!trialPeriod, hasCanceledSubscription)}
        </Button>
      ) : null}
    </SectionContainer>
  );
};

const SectionContainer = styled(SessionContainerBase)`
  background: var(--background-color);

  padding-top: var(--spacing-48);
  padding-left: var(--spacing-64);
  padding-right: var(--spacing-64);

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-48);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    background: none;
  }
`;

const Header = styled.div`
  @media ${(p) => p.theme.queries.phoneAndDown} {
    margin-bottom: -16px;
  }
`;

const TestimonialContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    flex-direction: column;
    gap: var(--spacing-48);
  }
`;

const TestimonialItem = styled.div`
  flex: 1 1 33.33333%;
  max-width: 33.33333%;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    flex: 1;
    max-width: 100%;
  }
`;

export default CreatorTestimonialsSection;
