import { Sessions } from '@solin-fitness/types';
import { DateTime } from 'luxon';

const getFields = (session: Sessions) => {
  if (session.type === 'liveWorkout') {
    const start = new Date(session.date);
    const now = new Date();
    const end = new Date(session.date);
    end.setMinutes(end.getMinutes() + session.data.length);

    const isLive = now > start && now < end;
    return {
      id: session.data.id,
      type: session.type,
      image: session.data.thumbnailPublicId,
      title: session.data.title,
      date: new Date(session.date),
      hasAccess: session.hasAccess,
      isIncludedInMembership: session.data.isIncludedInMembership,
      isForSale: session.data.isForSale,
      amount: session.data.amount,
      isLive,
      tag: 'Live',
    };
  } else if (session.type === 'workoutDrop') {
    return {
      id: session.data.id,
      type: session.type,
      image: session.data.thumbnailPublicId,
      title: session.data.title,
      date: new Date(session.date),
      hasAccess: session.hasAccess,
      isIncludedInMembership: true,
      isForSale: false,
      isLive: false,
      tag: 'Drop',
    };
  } else {
    return {
      id: session.data.id,
      type: session.type,
      image: session.data.backgroundImagePublicId,
      title: session.data.title,
      date: new Date(session.date),
      hasAccess: session.hasAccess,
      isIncludedInMembership: false,
      isForSale: true,
      amount: session.data.amount,
      isLive: false,
      tag: 'Event',
    };
  }
};

const getSessionDateFormat = (date: Date) => {
  // Sat, Mar 7 at 6:30pm
  const dayAndMonth = DateTime.fromJSDate(date).toFormat('ccc, LLL d');
  const time = DateTime.fromJSDate(date).toFormat('h:mm');
  const meridiem = DateTime.fromJSDate(date).toFormat('a');
  return `${dayAndMonth} at ${time}${meridiem.toLowerCase()}`;
};

// to purchase page OR to live room
const getLink = (type: Sessions['type'], id: number, isLive?: boolean) => {
  if (type === 'liveWorkout') {
    return isLive ? `live/${id}` : `live/${id}/purchase`;
  } else if (type === 'event') {
    return isLive ? `event/${id}/room` : `event/${id}/purchase`;
  } else {
    return '';
  }
};

export { getFields, getSessionDateFormat, getLink };
