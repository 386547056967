import { PageTestimonials } from '@solin-fitness/types';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import Image from 'next/image';
import buildImageUrl from 'cloudinary-build-url';
import clsx from 'clsx';

const TestimonialSection = ({
  testimonials,
  className,
}: {
  testimonials: PageTestimonials[];
  className?: string;
}) => {
  if (testimonials.length) {
    return (
      <section className={clsx('py-11 px-5', className)}>
        <h2 className="font-hk text-2xl font-medium leading-sm pb-6 ">
          Testimonials
        </h2>
        {testimonials.slice(0, 3).map((testimonial, index) => (
          <Testimonial key={index} {...testimonial} />
        ))}
      </section>
    );
  }

  return null;
};

export { TestimonialSection };

const Testimonial = ({ image, name, testimonial }: PageTestimonials) => {
  return (
    <article className="pb-12 last:pb-0">
      <AspectRatio.Root
        ratio={335 / 297}
        className="rounded-xl overflow-hidden pb-5"
      >
        <Image
          src={buildImageUrl(image, {})}
          layout="fill"
          alt="testimonial"
          objectFit="cover"
        />
      </AspectRatio.Root>
      <div className="pt-5 pb-3 leading-none">
        <Image
          width={18}
          height={13}
          src="/images/quotes.png"
          objectFit="contain"
        />
      </div>
      <p className="font-hk text-base text-black-900 leading-md pb-2">
        {testimonial}
      </p>
      <h6 className="font-hk text-base font-semibold text-black-900 capitalize">
        {name}
      </h6>
    </article>
  );
};
