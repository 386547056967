import { PageConfigResponse } from '@solin-fitness/types';
import {
  EventResponse,
  LiveWorkoutResponse,
  PageSubscriptions,
  PurchasedEventResponse,
  SubscriptionResponse,
  SubscriptionStatus,
  UserMetaDataResponse,
  WorkoutResponse,
  PurchasedProgramResponse,
  PurchasedClassesResponse,
} from '@solin-fitness/types';

export const hasSubscription = (
  metadata: UserMetaDataResponse | undefined,
  subscriptions: PageSubscriptions[] | undefined,
  authenticated: boolean,
): [boolean, SubscriptionResponse | undefined] => {
  if (!metadata || !authenticated) {
    return [false, undefined];
  }

  const pageSubscriptions = subscriptions?.map((val) => val.priceId);
  const userSubscriptions = metadata.subscriptions.filter((val) =>
    pageSubscriptions?.includes(val.priceId),
  );
  for (const subscription of userSubscriptions) {
    /**
     * loop through users subscriptions to page
     * note: changing from finding one subscription to finding all is a user can have
     * a canceled subscription and an active subscription and this could result
     * in them returning the canceled subscription and preventing them from
     * having access when they actually do
     */
    if (
      [
        SubscriptionStatus.active,
        SubscriptionStatus.trialing,
        SubscriptionStatus.pastDue,
        SubscriptionStatus.unpaid,
        SubscriptionStatus.incomplete,
      ].includes(subscription.status)
    ) {
      const end = subscription.currentPeriodEnd * 1000;
      const curr = new Date().getTime();
      // if was cancelled and billing period end has passed
      if (subscription.cancelAtPeriodEnd && curr > end) {
        continue;
      }
      return [true, subscription];
    }
  }
  return [false, undefined];
};

export const hasPurchasedClass = (
  metadata: UserMetaDataResponse | undefined,
  data: LiveWorkoutResponse | WorkoutResponse | EventResponse | undefined,
  type: 'liveWorkout' | 'workoutDrop' | 'event',
  authenticated: boolean,
): boolean => {
  if (!metadata || !authenticated || type !== 'liveWorkout') {
    return false;
  }

  if (!data) {
    return false;
  }

  const userPurchasedClass = metadata.purchasedClasses.find(
    (val) => val.liveWorkout.id === data.id,
  );
  return !!userPurchasedClass;
};

export const hasPurchasedLiveWorkout = (
  purchasedLiveWorkouts: PurchasedClassesResponse[],
  liveWorkoutId: number,
): boolean =>
  !!purchasedLiveWorkouts.find((data) => data.liveWorkout.id === liveWorkoutId);

export const hasPurchasedEvent = (
  purchasedEvents: PurchasedEventResponse[],
  eventId: number,
): boolean => !!purchasedEvents.find((data) => data.event.id === eventId);

export const hasPurchasedProgram = (
  purchasedProgram: PurchasedProgramResponse[],
  programId: number,
): boolean => !!purchasedProgram.find((data) => data.program.id === programId);

export const getAdmin = (pageConfig: PageConfigResponse) => {
  const admin = pageConfig.users.find((u) => u.type === 2);
  return admin;
};
