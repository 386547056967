import { Sessions } from '@solin-fitness/types';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import {
  getFields,
  getLink,
  getSessionDateFormat,
} from 'new-components/creator-landing-page/upcoming-sessions-helper';
import Image from 'next/image';
import buildImageUrl from 'cloudinary-build-url';
import Button from 'shared/Button';
import { IconNames } from 'shared/Icons';
import clsx from 'clsx';

const UpcomingSessionSection = ({
  sessions,
  page,
  className,
}: {
  sessions: Sessions[];
  page: string;
  className?: string;
}) => {
  if (sessions.length) {
    return (
      <section className={clsx('pt-11 pb-5', className)}>
        <h2 className="text-2xl font-hk font-medium leading-sm pb-6 px-5">
          Upcoming Sessions
        </h2>
        <div className="max-w-full overflow-x-auto overflow-y-hidden scroll-px-5 px-5 snap-mandatory snap-x whitespace-nowrap">
          {sessions.map((session, index) => (
            <article
              key={index}
              className="inline-block snap-start pr-4 last-of-type:pr-0"
            >
              <Session session={session} page={page} />
            </article>
          ))}
        </div>
      </section>
    );
  }

  return null;
};

export { UpcomingSessionSection };

const Session = ({ session, page }: { session: Sessions; page: string }) => {
  const fields = getFields(session);

  const dateString = getSessionDateFormat(fields.date);

  return (
    <div className="w-[319px] pb-6">
      <AspectRatio.Root ratio={16 / 9} className="rounded-lg overflow-hidden">
        <Image
          src={buildImageUrl(fields.image, {})}
          layout="fill"
          alt="upcoming session"
          objectFit="cover"
        />
      </AspectRatio.Root>
      <h6 className="font-hk text-base font-medium text-black-900 leading-md truncate pt-4 pb-1">
        {fields.title}
      </h6>
      <p className="font-hk text-base font-normal text-gray-700 leading-md pb-6">
        {dateString}
        <span className="px-[6px] text-gray-300">&#183;</span>
        {fields.tag}
      </p>
      <SessionButton
        page={page}
        id={fields.id}
        type={fields.type}
        hasAccess={fields.hasAccess}
        isForSale={fields.isForSale}
        isIncludedInMembership={fields.isIncludedInMembership}
        amount={fields.amount}
        isLive={fields.isLive}
      />
    </div>
  );
};

type ForSaleSession = {
  isForSale: true;
  isIncludedInMembership: boolean;
  amount?: number;
};

type NotForSaleSession = {
  isForSale: false;
  isIncludedInMembership: boolean;
};

const SessionButton = ({
  type,
  id,
  page,
  hasAccess,
  isLive,
  ...props
}: {
  type: Sessions['type'];
  id: number;
  page: string;
  hasAccess: boolean;
  isLive: boolean;
} & (ForSaleSession | NotForSaleSession)) => {
  if (hasAccess && isLive) {
    // link to live workout room or event room
    const linkSuffix = getLink(type, id, true);
    const href = `/${page}/${linkSuffix}`;
    return (
      <Button variant="fill" size="medium" link href={href}>
        Join Live
      </Button>
    );
  } else if (hasAccess) {
    // no link, disabled button
    return (
      <Button
        variant="fill"
        size="medium"
        disabled
        withIcon
        iconId={IconNames.check}
      >
        Access Purchased
      </Button>
    );
  } else if (props.isForSale && props.isIncludedInMembership) {
    // purchase and subscribe
    const linkSuffix = getLink(type, id, false);
    const href = `/${page}/${linkSuffix}`;
    const amount = props.amount || 0;
    return (
      <div className="flex gap-2">
        <Button variant="fill" size="medium" link href={href}>
          Purchase ${Number((amount / 100).toFixed(2))}
        </Button>
        <Button
          variant="black-outline"
          size="medium"
          link
          href={`/${page}/subscription/purchase`}
        >
          Subscribe
        </Button>
      </div>
    );
  } else if (props.isForSale) {
    // purchase (live workout or event)
    const linkSuffix = getLink(type, id, false);
    const href = `/${page}/${linkSuffix}`;
    const amount = props.amount || 0;
    return (
      <Button variant="fill" size="medium" link href={href}>
        Purchase ${(amount / 100).toFixed(2)}
      </Button>
    );
  } else {
    // subscribe
    return (
      <Button
        variant="fill"
        size="medium"
        link
        href={`/${page}/subscription/purchase`}
      >
        Subscribe for Access
      </Button>
    );
  }
};
