import { Media, MediaContextProvider } from 'media';
import Button from 'shared/Button';
import Modal from 'shared/Modal';
import TextInput from 'shared/TextInput';
import { Formik, Form } from 'formik';
import SlidingModal from 'components/SlidingModal/SlidingModal';
import { useAddPhoneNumber } from 'queries/phone-number';
import { useState } from 'react';

const GetNotifiedModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return isOpen ? (
    <MediaContextProvider>
      <Media greaterThanOrEqual="md">
        <Modal
          isOpen={isOpen}
          onDismiss={onClose}
          maxWidth={498}
          theme="dark"
          title="Get Notified"
          subtitle="Receive updates when featured creators are launching new products. Be the first to know."
        >
          <PhoneNumberForm onClose={onClose} />
        </Modal>
      </Media>
      <Media lessThan="md">
        <SlidingModal isOpen={isOpen} onRequestClose={onClose} hideHeaderBorder>
          <PhoneNumberForm onClose={onClose} />
        </SlidingModal>
      </Media>
    </MediaContextProvider>
  ) : null;
};

export { GetNotifiedModal };

const PhoneNumberForm = ({ onClose }: { onClose: () => void }) => {
  const initialValues = {
    phoneNumber: '+ 1 ',
  };

  const handleAddPhoneNumber = useAddPhoneNumber();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(data) => {
        setIsSubmitting(true);
        handleAddPhoneNumber.mutate(
          {
            phoneNumber: data.phoneNumber
              .replace(/\+/g, '')
              .replace(/\s+/g, ''),
          },
          {
            onSuccess: () => {
              setIsSubmitting(false);
              setIsSubmitted(true);
            },
          },
        );
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="sm:hidden">
            <h3 className="text-lg font-semibold leading-md text-white-500 text-center pb-6">
              Join the Solin Circle
            </h3>

            <p className="text-base font-medium leading-sm text-white-500 text-center pb-6">
              {isSubmitted
                ? 'Thank you for subscribing!'
                : 'Get notified when featured creators are launching offerings. Be the first to know.'}
            </p>
          </div>
          <TextInput
            label="Phone Number"
            name="phoneNumber"
            autoFocus
            value={values.phoneNumber}
            onChange={() => undefined}
            rawOnChange={(e) => {
              const val = e.currentTarget.value;
              let newTxt = '';
              if (
                (e.nativeEvent as InputEvent).inputType ===
                'deleteContentBackward'
              ) {
                if (val.length < 4) {
                  return;
                }
                const txt = val.replace(/\+ 1 /g, '');
                // if last character is a space, remove it
                if (txt[txt.length - 1] === ' ') {
                  newTxt = txt.slice(0, txt.length - 1);
                } else {
                  newTxt = txt;
                }
                newTxt = '+ 1 ' + newTxt;
                setFieldValue('phoneNumber', newTxt);
                return;
              }

              if (val.length > 16) {
                return;
              }
              let txt = val.replace(/\+ 1 /g, '').replace(/\D/g, '');
              txt = '+ 1 ' + txt;
              for (let i = 0; i < txt.length; i++) {
                newTxt += txt[i];
                if (i === 6 || i === 9) {
                  newTxt += ' ';
                }
              }
              setFieldValue('phoneNumber', newTxt);
            }}
            background="light"
            fullWidth
          />
          <div className="pt-4 pb-1 grid place-content-center">
            <Button
              variant="fill"
              size="medium"
              type="submit"
              disabled={values.phoneNumber.length < 16}
              isLoading={isSubmitting}
            >
              {isSubmitted ? 'Submitted' : 'Submit'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
