// code copied and slightly modified from
// https://github.com/DimitryDushkin/sliding-pane

import React from 'react';
import Modal from 'react-modal';
import Icon, { IconNames } from 'shared/Icons';
import styled, { createGlobalStyle } from 'styled-components';
import { useUpdateStateIfMounted } from 'hooks/useUpdateStateIfMounted';

const BodyNoScroll = createGlobalStyle`
body {
  overflow: hidden;
}
`;

const Header = styled.div<{ hideBorder?: boolean }>`
  display: flex;
  flex: 0 0 64px;
  align-items: center;
  background: var(--color-black-700);
  height: 64px;
  border-bottom: ${(p) =>
    p.hideBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.1)'};
  border-radius: 10px;
  width: 100%;
  justify-content: flex-end;
  padding: 24px;
`;

const CloseIconWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Content = styled.div`
  position: relative;
  overflow-y: auto;
  padding: 24px 32px;
  flex: 1 1 auto;
`;

const CLOSE_TIMEOUT = 500;

type Props = {
  isOpen?: boolean;
  from?: 'left' | 'right' | 'bottom'; // "right" — default
  children: React.ReactNode;
  width?: string; // CSS string for width
  shouldCloseOnEsc?: boolean;
  hideHeader?: boolean;
  hideHeaderBorder?: boolean;
  onRequestClose: () => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
};

export const SlidingModal = ({
  isOpen,
  onRequestClose,
  onAfterOpen,
  onAfterClose,
  children,
  from = 'bottom',
  width = '100%',
  shouldCloseOnEsc,
  hideHeader,
  hideHeaderBorder,
}: Props) => {
  const directionClass = `slide-pane_from_${from}`;
  const state = useUpdateStateIfMounted(false);
  const wasOpen = state[0];
  const setWasOpen = state[1];

  const handleAfterOpen = () => {
    onAfterOpen?.();
    setTimeout(() => {
      setWasOpen(true);
    }, 0);
  };

  const handleAfterClose = () => {
    onAfterClose?.();
    setTimeout(() => {
      setWasOpen(false);
    }, 0);
  };

  return (
    <>
      {isOpen && <BodyNoScroll />}

      <Modal
        ariaHideApp={false}
        overlayClassName={{
          base: 'slide-pane__overlay',
          afterOpen: wasOpen ? 'overlay-after-open' : '',
          beforeClose: 'overlay-before-close',
        }}
        className={{
          base: `slide-pane ${directionClass}`,
          afterOpen: wasOpen ? 'content-after-open' : '',
          beforeClose: 'content-before-close',
        }}
        style={{
          content: { width: width || '80%' },
        }}
        closeTimeoutMS={CLOSE_TIMEOUT}
        isOpen={isOpen ?? false}
        shouldCloseOnEsc={shouldCloseOnEsc}
        onAfterOpen={handleAfterOpen}
        onAfterClose={handleAfterClose}
        onRequestClose={onRequestClose}
      >
        {!hideHeader ? (
          <Header hideBorder={hideHeaderBorder}>
            <CloseIconWrap onClick={onRequestClose} role="button" tabIndex={0}>
              <Icon id={IconNames.x} style={{ color: 'white', width: 24 }} />
            </CloseIconWrap>
          </Header>
        ) : null}
        <Content>{children}</Content>
      </Modal>
    </>
  );
};

export default SlidingModal;
